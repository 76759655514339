// src/hooks/useSocket.js
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io('http://85.208.48.19:3000'); // Ajuste a URL conforme necessário

export function useSocket(searchId) {
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchId) {
      socket.on(`search-results-${searchId}`, (data) => {
        setResults(prevResults => [...prevResults, ...data]);
      });
    }

    return () => {
      if (searchId) {
        socket.off(`search-results-${searchId}`);
      }
    };
  }, [searchId]);

  return results;
}