import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import FlightResults from './components/FlightResults';

// Componente de depuração
const DebugComponent = () => <div className="debug">Debug: App está renderizando</div>;

function App() {
  const [formData, setFormData] = useState({
    origin: '',
    destination: '',
    departureDate: '',
    returnDate: '',
    adults: 1,
    children: 0,
    infants: 0,
    cabin: 'all'
  });
  const [searchId, setSearchId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const API_URL = 'http://85.208.48.19:3000/api/smiles/search-flights';

    try {
      const requestData = {
        origin: formData.origin,
        destination: formData.destination,
        departureDate: formData.departureDate,
        returnDate: formData.returnDate,
        adults: parseInt(formData.adults),
        children: parseInt(formData.children),
        infants: parseInt(formData.infants),
        cabin: formData.cabin
      };

      const response = await axios.post(API_URL, requestData);
      setSearchId(response.data.searchId);
    } catch (error) {
      console.error('Erro ao iniciar a busca:', error);
      setError(error.response?.data?.message || 'Ocorreu um erro ao buscar os voos');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App container mt-5">
      <h1 className="mb-4">Busca de Voos com Milhas</h1>
      <DebugComponent />
      
      {isLoading ? (
        <div className="alert alert-info">Carregando...</div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="origin" className="form-label">Origem</label>
                <input
                  type="text"
                  className="form-control"
                  id="origin"
                  name="origin"
                  value={formData.origin}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="destination" className="form-label">Destino</label>
                <input
                  type="text"
                  className="form-control"
                  id="destination"
                  name="destination"
                  value={formData.destination}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="departureDate" className="form-label">Data de Ida</label>
                <input
                  type="date"
                  className="form-control"
                  id="departureDate"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="returnDate" className="form-label">Data de Volta</label>
                <input
                  type="date"
                  className="form-control"
                  id="returnDate"
                  name="returnDate"
                  value={formData.returnDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="adults" className="form-label">Adultos</label>
                <input
                  type="number"
                  className="form-control"
                  id="adults"
                  name="adults"
                  value={formData.adults}
                  onChange={handleChange}
                  min="1"
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="children" className="form-label">Crianças</label>
                <input
                  type="number"
                  className="form-control"
                  id="children"
                  name="children"
                  value={formData.children}
                  onChange={handleChange}
                  min="0"
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="infants" className="form-label">Bebês</label>
                <input
                  type="number"
                  className="form-control"
                  id="infants"
                  name="infants"
                  value={formData.infants}
                  onChange={handleChange}
                  min="0"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="cabin" className="form-label">Classe</label>
              <select
                className="form-select"
                id="cabin"
                name="cabin"
                value={formData.cabin}
                onChange={handleChange}
              >
                <option value="all">Todas</option>
                <option value="economy">Econômica</option>
                <option value="business">Executiva</option>
                <option value="first">Primeira Classe</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">Buscar Voos</button>
          </form>
          {searchId && <FlightResults searchId={searchId} />}
        </>
      )}
    </div>
  );
}

export default App;