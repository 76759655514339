// src/components/FlightResults.js
import React from 'react';
import { useSocket } from '../hooks/useSocket';

function FlightResults({ searchId }) {
  const results = useSocket(searchId);

  return (
    <div className="flight-results">
      <h2>Resultados da Busca</h2>
      {results.length > 0 ? (
        results.map((itinerary, index) => (
          <div key={index} className="itinerary">
            <h3>Itinerário {index + 1}</h3>
            <p>Preço Total: {itinerary.total_price}</p>
            {itinerary.flights.map((flight, flightIndex) => (
              <div key={flightIndex} className="flight">
                <p>Voo: {flight.flight_number}</p>
                <p>De: {flight.departure_airport} - Para: {flight.arrival_airport}</p>
                <p>Saída: {flight.departure_time} - Chegada: {flight.arrival_time}</p>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p>Aguardando resultados...</p>
      )}
    </div>
  );
}

export default FlightResults;